import type { AIMessageChunk } from '@langchain/core/messages'
import LinearProgress from '@mui/material/LinearProgress'
import { highlighter } from '@nlux/highlighter'
import {
  type MarkdownStreamParser,
  createMarkdownStreamParser,
} from '@nlux/markdown'
import '@nlux/themes/nova.css'
import { getUser } from '@northvolt/snowflake'
import { Box, Stack, Typography } from '@northvolt/ui'
import { useGetUserAssistants } from 'client/wattson-client'
import { type StreamingChatMessage, useChat } from 'components/Chat'
import ChatTextInput from 'components/Chat/ChatTextInput'
import Message from 'components/Message'
import InsightsSection from 'components/sections/InsightsSection'
import WelcomingSection from 'components/sections/WelcomingSection'
import { ChatProvider } from 'contexts/ChatContext'
import { InsightsProvider } from 'contexts/InsightsContext'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

export default () => {
  const streamingMessageRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    if (window && document) {
      const timeoutId = setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        })
      }, 100)
      return () => clearTimeout(timeoutId) // Clear the timeout if the component unmounts
    }
  }, [])

  useEffect(() => {
    const cleanup = scrollToBottom()
    return () => {
      if (cleanup) cleanup()
    }
  }, [scrollToBottom])

  const user = getUser()
  const userInitials = user?.initials || 'You'

  const { isLoading: isAssistantsLoading, data } = useGetUserAssistants()
  const assistants = data?.data || []

  const [markdownParser, setMarkdownParser] = useState<MarkdownStreamParser>()

  const onChunk = useCallback(
    (chunk: AIMessageChunk) => {
      const content = typeof chunk.content === 'string' ? chunk.content : ''
      if (markdownParser) {
        markdownParser.next(content)
      }
      scrollToBottom()
    },
    [markdownParser, scrollToBottom],
  )

  const onError = useCallback(
    (error: any) => {
      console.error(error)
      markdownParser?.complete()
    },
    [markdownParser],
  )

  const onSuccess = useCallback(() => {
    markdownParser?.complete()
    scrollToBottom()
  }, [markdownParser, scrollToBottom])

  const {
    sendMessage,
    stopStream,
    isLoading: isMessageLoading,
    messages,
    chatHistoryLoader,
  } = useChat('', {
    onChunk,
    onError,
    onSuccess,
  })

  useLayoutEffect(() => {
    const node = streamingMessageRef.current
    if (node !== null) {
      const newParser = createMarkdownStreamParser(node, {
        syntaxHighlighter: highlighter,
        waitTimeBeforeStreamCompletion: 20000,
        streamingAnimationSpeed: 5,
        showCodeBlockCopyButton: false,
      })
      setMarkdownParser(newParser)
    }
  }, [messages, streamingMessageRef])

  if (!messages && chatHistoryLoader.isLoading) {
    return (
      <>
        <LinearProgress
          sx={{
            backgroundColor: 'inherit',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'var(--nv-palette-success-main)',
            },
          }}
        />
      </>
    )
  }

  if (chatHistoryLoader.isError) {
    return (
      <Typography>
        Error Loading Chat: {chatHistoryLoader.error.message}
      </Typography>
    )
  }
  return (
    <>
      <InsightsProvider>
        <ChatProvider>
          <Box
            sx={{
              width: { xs: '100%', sm: '100%', xl: '70%' },
              margin: '0 auto',
              marginTop: 2,
              textAlign: 'left',
              paddingBottom: messages.length ? 18 : 0,
            }}>
            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems={{ xs: 'center', sm: 'center' }}
              spacing={2}
              sx={{ width: '100%' }}>
              {!messages.length && (
                <>
                  <WelcomingSection />
                  <InsightsSection />
                </>
              )}

              {messages
                .sort((a, b) => Number(a.created_at) - Number(b.created_at))
                .filter(
                  ({ role, content }) =>
                    content && ['ai', 'human'].includes(role),
                )
                .map((message: StreamingChatMessage, i: number, arr: any[]) => {
                  if (
                    message.role === 'ai' &&
                    (message?.tool_calls?.length ?? 0) > 0
                  ) {
                    return null // TODO: Render tool call
                  } else if (message.role === 'tool') {
                    return null // TODO: Render tool output
                  } else if (message.content !== '') {
                    return (
                      <Message
                        message={message}
                        isLoading={isMessageLoading && arr.length - 1 === i}
                        initials={userInitials}
                        key={`message-${message.id}-${message.created_at}`}
                        streamingContentRef={
                          arr.length - 1 === i ? streamingMessageRef : undefined
                        }
                      />
                    )
                  }
                })}
            </Stack>
          </Box>
          <ChatTextInput
            sendMessage={(msg: string) => sendMessage(msg)}
            isLoading={isMessageLoading}
            scrollToBottom={scrollToBottom}
            stopStream={stopStream}
            disabled={
              chatHistoryLoader.isLoading ||
              isAssistantsLoading ||
              assistants.length < 1
            }
          />
        </ChatProvider>
      </InsightsProvider>
    </>
  )
}
