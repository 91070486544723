import type { QueryKey, UseQueryResult } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'
import type {
  AssistantEnum,
  Chat,
  HTTPValidationError,
  Insight,
  Message,
  ToolCall,
} from 'client/model'

/**
 * Streaming chat message
 */
interface StreamingChatMessage extends Message {
  tool_calls?: ToolCall[]
}

type HistoryLoader = (
  msg: string,
) => UseQueryResult<
  AxiosResponse<Chat, any>,
  AxiosError<HTTPValidationError, any>
> & { queryKey: QueryKey }

/**
 * Chat context parameters
 */
type ChatContextParams = {
  allMessages: StreamingChatMessage[]
  sendMessage: (message: string) => void
  chatId: string
  responseMessage: string
  success?: boolean
  chatHistoryLoader?: UseQueryResult<
    AxiosResponse<Chat, any>,
    AxiosError<HTTPValidationError, any>
  > & {
    queryKey: QueryKey
  }
  stopStream?: () => void
  isLoading?: boolean
}

/**
 * Insights context type
 */
type InsightsContextType = {
  hasInsights: boolean
  drop: (i: number) => void
  clean: () => void
  reload: () => void
  content?: Insight[]
}

/**
 * Chat commands
 * actions to be dispatched to the chat reducer
 */
export enum ChatCommands {
  ADD_TO_BUFFER = 'ADD_TO_BUFFER',
  FLUSH_BUFFER = 'FLUSH_BUFFER',
  SET_CHAT_ID = 'SET_CHAT_ID',
  SET_SUCCESS = 'SET_SUCCESS',
  SET_ASSISTANT = 'SET_ASSISTANT',
  SET_CONTROLLER = 'SET_CONTROLLER',
  SET_ALL_MESSAGES = 'SET_ALL_MESSAGES',
  RESET_RESPONSE = 'RESET_RESPONSE',
}

/**
 * Chat actions
 * actions to be performed by the chat reducer
 */
type ChatActions =
  | { type: 'ADD_TO_BUFFER'; payload: string }
  | { type: 'FLUSH_BUFFER' }
  | { type: 'SET_CHAT_ID'; payload: string }
  | { type: 'SET_SUCCESS'; payload: boolean }
  | { type: 'SET_ASSISTANT'; payload: AssistantEnum }
  | { type: 'SET_CONTROLLER'; payload: AbortController | undefined }
  | { type: 'SET_ALL_MESSAGES'; payload: StreamingChatMessage[] }
  | { type: 'RESET_RESPONSE' }
  | { type: 'SET_LOADING'; payload: boolean }

interface ChatState {
  responseMessage: string
  buffer: string
  chatId: string
  success: boolean | undefined
  assistant: AssistantEnum | undefined
  controller: AbortController | undefined
  allMessages: StreamingChatMessage[]
  isLoading: boolean
}

export type {
  ChatActions,
  ChatContextParams,
  ChatState,
  HistoryLoader,
  InsightsContextType,
  StreamingChatMessage,
}
