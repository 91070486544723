import { getIdToken } from '@northvolt/snowflake'
import {
  type ChatActions,
  ChatCommands,
  type ChatContextParams,
  type ChatState,
} from 'Types'
import type { AxiosResponse } from 'axios'
import type { Assistant, Chat } from 'client/model'
import { useGetTopAssistant, useGetUserChat } from 'client/wattson-client'
import debounce from 'helpers/debounce'
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'

/**
 * Chat reducer
 * State management for chat context
 *
 * @param state - current state
 * @param action  - action to be performed, listed in ChatCommands enum
 * @returns
 */
const chatReducer = (state: ChatState, action: ChatActions): ChatState => {
  switch (action.type) {
    case ChatCommands.ADD_TO_BUFFER:
      return { ...state, buffer: state.buffer + action.payload }
    case ChatCommands.FLUSH_BUFFER:
      return {
        ...state,
        responseMessage: state.responseMessage + state.buffer,
        buffer: '',
      }
    case ChatCommands.SET_CHAT_ID:
      return { ...state, chatId: action.payload }
    case ChatCommands.SET_SUCCESS:
      return { ...state, success: action.payload }
    case ChatCommands.SET_ASSISTANT:
      return { ...state, assistant: action.payload }
    case ChatCommands.SET_CONTROLLER:
      return { ...state, controller: action.payload }
    case ChatCommands.SET_ALL_MESSAGES:
      return { ...state, allMessages: action.payload }
    case ChatCommands.RESET_RESPONSE:
      return { ...state, responseMessage: '', success: false }
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}

const ChatContext = createContext<ChatContextParams | undefined>(undefined)

const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(chatReducer, {
    responseMessage: '',
    buffer: '',
    chatId: '',
    success: undefined,
    assistant: undefined,
    controller: undefined,
    allMessages: [],
    isLoading: false,
  })

  const debouncedFlushRef = useRef<ReturnType<typeof debounce>>()
  const userChat = useGetUserChat<AxiosResponse<Chat>>(state.chatId)
  const assistantLoader = useGetTopAssistant<AxiosResponse<Assistant>>()

  const chatIdSetup = useCallback(
    (newChatId?: string) =>
      dispatch({
        type: 'SET_CHAT_ID',
        payload: state.chatId || newChatId || '',
      }),
    [state.chatId],
  )

  const sendMessage = async (input: string) => {
    dispatch({ type: 'RESET_RESPONSE' })
    dispatch({ type: 'SET_CONTROLLER', payload: new AbortController() })
    dispatch({ type: 'SET_LOADING', payload: true })

    const url = `${import.meta.env.VITE_API_URI}api/chat/${
      state.assistant ?? 'foundation'
    }/stream_events`
    const body = JSON.stringify({
      input,
      config: { configurable: { chat_id: state.chatId } },
    })
    const headers = {
      Authorization: `Bearer ${getIdToken()}`,
      'Content-Type': 'application/json',
    }
    const signal = state.controller?.signal

    try {
      const response = await fetch(url, {
        signal,
        method: 'POST',
        headers,
        body,
      })

      if (!response.body) {
        console.error('Response body is null')
        return
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .pipeThrough(
          new TransformStream({
            transform(chunk: string) {
              if (/on_chain_start/.test(chunk)) {
                const id = /(?<=chat_id":)([^,}]+)/.exec(chunk)?.[1]
                if (id && !state.chatId) chatIdSetup(id)
              }
              if (/on_chat_model_stream|on_llm_stream/.test(chunk)) {
                if (/\[Document\(metadata=\{/.test(chunk)) return
                const match = /"content":"((?:\\.|[^"\\])*)"/i.exec(chunk)
                if (match?.[1]) {
                  const content = JSON.parse(`"${match[1]}"`)
                  dispatch({ type: 'ADD_TO_BUFFER', payload: content })
                }
              }
            },
          }),
        )
        .getReader()

      while (true) {
        const { done } = await reader.read()
        if (done) {
          userChat.refetch()
          dispatch({ type: 'SET_CONTROLLER', payload: undefined })
          dispatch({ type: 'SET_SUCCESS', payload: true })
          dispatch({ type: 'SET_LOADING', payload: false })
          break
        }
      }
    } catch (error) {
      console.error('Error in sendMessage:', error)
      dispatch({ type: 'SET_SUCCESS', payload: false })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  useEffect(() => {}, [state.isLoading])

  const stopStream = useMemo(() => {
    return () => {
      state.controller?.abort()
      dispatch({ type: 'SET_CONTROLLER', payload: undefined })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }, [state.controller])

  useEffect(() => {
    debouncedFlushRef.current = debounce(() => {
      if (state.buffer) {
        dispatch({ type: 'FLUSH_BUFFER' })
      }
    }, 500)
  }, [state.buffer])

  useEffect(() => {
    debouncedFlushRef.current?.()
  }, [state.buffer])

  useEffect(() => {
    if (userChat.data) {
      const { messages, assistant: chatAssistant } = userChat.data?.data || {}
      if (messages) dispatch({ type: 'SET_ALL_MESSAGES', payload: messages })
      if (chatAssistant)
        dispatch({ type: 'SET_ASSISTANT', payload: chatAssistant })
    }
  }, [userChat.data])

  useEffect(() => {
    if (assistantLoader.data?.data) {
      const { path } = assistantLoader.data?.data || {}
      dispatch({ type: 'SET_ASSISTANT', payload: path })
    }
  }, [assistantLoader.data])

  return (
    <ChatContext.Provider
      value={{
        chatId: state.chatId,
        success: state.success,
        responseMessage: state.responseMessage,
        sendMessage,
        allMessages: state.allMessages,
        stopStream: state.controller ? stopStream : undefined,
        isLoading: state.isLoading,
      }}>
      {children}
    </ChatContext.Provider>
  )
}

const useChat = () => {
  const chatContext = useContext(ChatContext)
  if (chatContext === undefined) {
    throw new Error('useChat must be used within a ChatProvider')
  }
  return chatContext
}

export { ChatProvider, useChat }
