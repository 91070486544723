import { AIMessageChunk } from '@langchain/core/messages'
import type { EventSourceMessage } from '@microsoft/fetch-event-source'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { getIdToken } from '@northvolt/snowflake'
import type { AxiosResponse } from 'axios'
import type { Assistant, Message as TMessage } from 'client/model'
import { useGetTopAssistant, useGetUserChat } from 'client/wattson-client'
import { useCallback, useEffect, useRef, useState } from 'react'

export interface StreamCallback {
  onSuccess?: () => void
  onChunk?: (chunk: AIMessageChunk) => void
  onError?: (error: any) => void
}

export type StreamingChatMessage = TMessage & { isLoading?: boolean }

export function useChat(
  existingChatId?: string,
  callbacks: StreamCallback = {},
) {
  const assistantLoader = useGetTopAssistant<AxiosResponse<Assistant>>()
  const [chatId, setChatId] = useState<string | undefined>(existingChatId)
  const [messages, setMessages] = useState<StreamingChatMessage[]>([])
  const [assistant, setAssistant] = useState<string | undefined>(undefined)
  const [controller, setController] = useState<AbortController | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const chatHistoryLoader = useGetUserChat(chatId ? chatId : '')

  useEffect(() => {
    if (assistantLoader.data?.data) {
      const { path } = assistantLoader.data?.data || {}
      setAssistant(path)
    }
  }, [assistantLoader.data])

  useEffect(() => {
    if (existingChatId) {
      setChatId(existingChatId)
      chatHistoryLoader.refetch()
    }
  }, [existingChatId])

  useEffect(() => {
    if (chatHistoryLoader?.data) {
      const data = chatHistoryLoader.data?.data
      if (data.messages && messages.length < data.messages.length)
        setMessages(data.messages)
      setAssistant(chatHistoryLoader.data?.data?.assistant)
    }
  }, [chatHistoryLoader.data])

  const chunkRef = useRef(callbacks.onChunk)
  chunkRef.current = callbacks.onChunk

  const successRef = useRef(callbacks.onSuccess)
  successRef.current = callbacks.onSuccess

  const errorRef = useRef(callbacks.onError)
  errorRef.current = callbacks.onError

  function handleStreamEvent(rawMessage: EventSourceMessage) {
    if (rawMessage.event === 'end') {
      setController(null)
    } else if (rawMessage.event === 'data') {
      try {
        const message = JSON.parse(rawMessage.data)
        // If we started a chat without a chat ID, we can find it in the metadata of any stream event
        if (!chatId) {
          setChatId(message.metadata.chat_id)
        }

        if (
          message.event === 'on_llm_stream' ||
          message.event === 'on_chat_model_stream'
        ) {
          if (message.data.chunk) {
            const chunk = new AIMessageChunk(message.data.chunk)
            chunkRef.current?.(chunk)
          }
        } else if (message.event === 'on_tool_start') {
          // console.log("Got Tool Call:", message);
          const toolCallMessage = {
            id: Number.MAX_SAFE_INTEGER - 2,
            content: '',
            role: 'ai',
            created_at: new Date().toISOString(),
            tool_calls: [message.data],
          }
          setMessages(prevMessages => [...prevMessages, toolCallMessage])
        } else if (message.event === 'on_tool_end') {
          // console.log("Got Tool End:", message);
          const toolOutputMessage = {
            id: Number.MAX_SAFE_INTEGER - 1,
            content: message.data,
            role: 'tool',
            created_at: new Date().toISOString(),
          }
          setMessages(prevMessages => [...prevMessages, toolOutputMessage])
        }
      } catch (error) {
        console.error('Error parsing message', error)
      }
    }
  }

  const sendMessage = useCallback(
    async (input: string) => {
      const userMessage = {
        id: Number.MAX_SAFE_INTEGER - 100,
        content: input,
        role: 'human',
        created_at: new Date().toISOString(),
        isLoading: false,
      }
      const streamingResponse = {
        id: Number.MAX_SAFE_INTEGER,
        content: ' ',
        role: 'ai',
        created_at: new Date().toISOString(),
        isLoading: true,
      }
      setMessages(prevMessages => [
        ...prevMessages,
        userMessage,
        streamingResponse,
      ])

      const abortController = new AbortController()
      setController(abortController)
      setIsLoading(true)

      const url = `${import.meta.env.VITE_API_URI}api/chat/${
        assistant ?? 'foundation'
      }/stream_events`
      const body = JSON.stringify({
        input,
        config: { configurable: { chat_id: chatId } },
      })
      const headers = {
        Authorization: `Bearer ${getIdToken()}`,
        'Content-Type': 'application/json',
      }

      try {
        await fetchEventSource(url, {
          signal: abortController.signal,
          method: 'POST',
          headers: headers,
          body: body,
          onmessage: handleStreamEvent,
          onclose() {
            setController(null)
            setIsLoading(false)
            chatHistoryLoader.refetch()
            successRef.current?.()
          },
          onerror(error) {
            setController(null)
            setIsLoading(false)
            errorRef.current?.(error)
            throw error
          },
          openWhenHidden: true,
        })
      } catch (error) {
        console.error('Error sending message', error)
        setController(null)
        setIsLoading(false)
        errorRef.current?.(error)
      }
    },
    [successRef, errorRef, chunkRef, setMessages, assistant, chatId],
  )

  const stopStream = useCallback(() => {
    controller?.abort()
    setController(null)
    setIsLoading(false)
  }, [controller])

  return {
    sendMessage: sendMessage,
    stopStream: controller ? stopStream : undefined,
    isLoading,
    messages,
    chatHistoryLoader,
  }
}
