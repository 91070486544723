import type { AIMessageChunk } from '@langchain/core/messages'
import LinearProgress from '@mui/material/LinearProgress'
import { highlighter } from '@nlux/highlighter'
import {
  type MarkdownStreamParser,
  createMarkdownStreamParser,
} from '@nlux/markdown'
import '@nlux/themes/nova.css'
import { getUser } from '@northvolt/snowflake'
import { Box, Stack, Typography } from '@northvolt/ui'
import { useGetUserAssistants } from 'client/wattson-client'
import { type StreamingChatMessage, useChat } from 'components/Chat'
import ChatTextInput from 'components/Chat/ChatTextInput'
import Message from 'components/Message'
import SuggestionBox from 'components/SuggestionBox'
import mixpanel from 'mixpanel-browser'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import Plot from 'react-plotly.js'
import { useParams } from 'react-router-dom'

export default () => {
  const { chatId: existingChatId } = useParams()

  const streamingMessageRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    if (window && document) {
      const timeoutId = setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        })
      }, 100)
      return () => clearTimeout(timeoutId) // Clear the timeout if the component unmounts
    }
  }, [])

  useEffect(() => {
    const cleanup = scrollToBottom()
    return () => {
      if (cleanup) cleanup()
    }
  }, [scrollToBottom])

  const user = getUser()
  const userInitials = user?.initials || 'You'

  const assistantLoader = useGetUserAssistants()
  const assistants = assistantLoader?.data?.data || []

  const [markdownParser, setMarkdownParser] = useState<MarkdownStreamParser>()

  const onChunk = useCallback(
    (chunk: AIMessageChunk) => {
      const content = typeof chunk.content === 'string' ? chunk.content : ''
      if (markdownParser) {
        markdownParser.next(content)
      }
      scrollToBottom()
    },
    [markdownParser, scrollToBottom],
  )

  const onError = useCallback(
    (error: any) => {
      console.error(error)
      markdownParser?.complete()
    },
    [markdownParser],
  )

  const onSuccess = useCallback(() => {
    markdownParser?.complete()
    scrollToBottom()
  }, [markdownParser, scrollToBottom])

  const { sendMessage, stopStream, isLoading, messages, chatHistoryLoader } =
    useChat(existingChatId, {
      onChunk,
      onError,
      onSuccess,
    })

  useLayoutEffect(() => {
    const node = streamingMessageRef.current
    if (node !== null) {
      const newParser = createMarkdownStreamParser(node, {
        syntaxHighlighter: highlighter,
        waitTimeBeforeStreamCompletion: 20000,
        streamingAnimationSpeed: 5,
        showCodeBlockCopyButton: false,
      })
      setMarkdownParser(newParser)
    }
  }, [messages, streamingMessageRef])

  const sendMessageAndTrack = useCallback(
    (message: string) => {
      mixpanel.track('suggestion-box', {
        title: message,
      })
      sendMessage(message)
    },
    [sendMessage], // Add sendMessage to the dependency array
  )

  if (!messages && chatHistoryLoader.isLoading) {
    return (
      <>
        <LinearProgress
          sx={{
            backgroundColor: 'inherit',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'var(--nv-palette-success-main)',
            },
          }}
        />
      </>
    )
  }

  if (chatHistoryLoader.isError) {
    return (
      <Typography>
        Error Loading Chat: {chatHistoryLoader.error.message}
      </Typography>
    )
  }

  if (
    chatHistoryLoader.isPending ||
    (chatHistoryLoader.isSuccess && chatHistoryLoader.data)
  ) {
    const currentAssistant = assistants?.find(
      a => a?.path === chatHistoryLoader?.data?.data.assistant,
    )
    const suggestions = currentAssistant?.suggestions || []

    return (
      <>
        <Box
          sx={{
            width: { xs: '100%', sm: '100%', xl: '70%' },
            margin: '0 auto',
            marginTop: 2,
            textAlign: 'left',
            paddingBottom: messages.length ? 18 : 0,
          }}>
          <Stack
            direction='column'
            justifyContent='flex-start'
            alignItems={{ xs: 'center', sm: 'center' }}
            spacing={2}
            sx={{ width: '100%' }}>
            {!messages.length && (
              <Stack>
                {suggestions.map((suggestion, index) => (
                  <div key={`suggestion-${suggestion.id}`}>
                    <Box
                      sx={{ height: '100%' }}
                      onClick={() =>
                        sendMessageAndTrack(suggestion.description)
                      }>
                      <SuggestionBox
                        title={suggestion.title}
                        label={suggestion.description}
                        delay={index / 5.0}
                      />
                    </Box>
                  </div>
                ))}
              </Stack>
            )}

            {messages
              .sort((a, b) => Number(a.created_at) - Number(b.created_at))
              .filter(
                ({ role, content, tool_calls }) =>
                  (content || tool_calls) &&
                  ['ai', 'human', 'tool'].includes(role),
              )
              .map((message: StreamingChatMessage, i: number, arr: any[]) => {
                if (
                  message.role === 'ai' &&
                  (message?.tool_calls?.length ?? 0) > 0
                ) {
                  if (
                    message.tool_calls &&
                    message.tool_calls[0].name === 'Plotting'
                  ) {
                    const args = message.tool_calls[0].args
                    const data = String(args?.plotly_data_json)
                    const layout = String(args?.plotly_layout_json)
                    if (!data || !layout) {
                      return null
                    }
                    return (
                      <Plot
                        key={`plot-${message.id}-${message.created_at}`}
                        data={JSON.parse(data)}
                        layout={JSON.parse(layout)}
                      />
                    )
                  } else {
                    return null
                  }
                } else if (message.role === 'tool') {
                  return null
                } else if (message.content !== '') {
                  return (
                    <Message
                      message={message}
                      isLoading={isLoading && arr.length - 1 === i}
                      initials={userInitials}
                      key={`message-${message.id}-${message.created_at}`}
                      streamingContentRef={
                        arr.length - 1 === i ? streamingMessageRef : undefined
                      }
                    />
                  )
                }
              })}
          </Stack>
        </Box>
        <ChatTextInput
          sendMessage={(msg: string) => sendMessage(msg)}
          isLoading={isLoading}
          scrollToBottom={scrollToBottom}
          stopStream={stopStream}
          disabled={chatHistoryLoader.isLoading}
        />
      </>
    )
  }
}
