import {
  ForceRefresh,
  SnowflakeProvider,
  configureSnowflake,
  getIdToken,
} from '@northvolt/snowflake'
import { CircularProgress, Grid, NVThemeProvider } from '@northvolt/ui'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import LoginForm from 'components/LoginForm'
import ErrorPage from 'routes/404'
import Chat from 'routes/Chat'
import HelpRoute from 'routes/Help'
import Landing from 'routes/Landing'
import Root from 'routes/Root'
import 'styles/globals.scss'
import 'styles/variables.scss'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: '', element: <Landing /> },
      { path: '/help', element: <HelpRoute /> },
    ],
  },
  {
    path: '/chat',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ':chatId',
        element: <Chat />,
      },
    ],
  },
])

function QueryProvider({ children }: { children: React.ReactNode }) {
  const [currentToken, setCurrentToken] = useState(getIdToken())
  axios.defaults.baseURL = import.meta.env.VITE_API_URI
  axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        //@ts-expect-error async works
        retry: async (failureCount, error) => {
          if (error) {
            await ForceRefresh()
            const idToken = getIdToken()
            if (!idToken) {
              window.location.reload()
            }
            setCurrentToken(idToken)
            return 1
          }
          return failureCount < 2
        },
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

function App() {
  useEffect(() => {
    configureSnowflake({
      AUTH_SCOPE: 'rome',
      AUTH_LOGIN_URL: import.meta.env.VITE_AUTH_LOGIN_URL,
      COGNITO_CLIENT_ID: import.meta.env.VITE_COGNITO_CLIENT_ID,
      COGNITO_USER_POOL_URL: import.meta.env.VITE_COGNITO_USER_POOL_URL,
      COGNITO_IDENTITY_PROVIDER: 'Northvolt',
    })

    mixpanel.init('5004b383b1f8c7aed61be4fc580af258', {
      api_host: 'https://api-eu.mixpanel.com',
      persistence: 'localStorage',
      track_pageview: true,
      debug: false,
    })
  }, [])

  return (
    <NVThemeProvider>
      <SnowflakeProvider
        loadingState={
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{ minHeight: '100vh' }}>
            <CircularProgress
              sx={{
                alignSelf: 'center',
                marginBottom: 3,
                textAlign: 'center',
                color: '#44a27a',
              }}
              size={64}
            />
          </Grid>
        }
        signedOutState={<LoginForm />}>
        <QueryProvider>
          <RouterProvider router={router} />
        </QueryProvider>
      </SnowflakeProvider>
    </NVThemeProvider>
  )
}

export default App
