import { Box, Typography } from '@northvolt/ui'
import { motion } from 'framer-motion'

export default function SuggestionBox({
  title,
  label,
  delay,
  keepLarge,
}: {
  title: string
  label: string
  delay?: number
  keepLarge?: boolean
}) {
  return (
    <motion.div
      style={{ height: '100%' }}
      initial={{ opacity: 0, translateY: -25 }}
      animate={{ translateY: 0, opacity: 1, from: 0, to: 1 }}
      transition={{ delay: delay || 0.1, ease: 'easeIn' }}>
      <Box
        sx={{
          border: 'solid 1px var(--nv-palette-grey-700)',
          width: '100%',
          height: '100%',
          padding: { xs: keepLarge ? '1em' : '0.5em', sm: '1em' },
          borderRadius: '10px',
          cursor: 'pointer',
          margin: { xs: 0.5, sm: 1 },
          '&:hover': {
            backgroundColor: 'var(--nv-palette-grey-700)',
          },
        }}>
        <Typography variant='subtitleSmall' sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography
          sx={{ display: { xs: keepLarge ? 'block' : 'none', sm: 'block' } }}
          variant='subtitleSmall'>
          {label}
        </Typography>
      </Box>
    </motion.div>
  )
}
